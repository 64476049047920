import { gsap, ScrollTrigger } from "gsap/all";
import CircleType from "circletype";
import zenscroll from "zenscroll";
import Splide from '@splidejs/splide';
import { Intersection } from '@splidejs/splide-extension-intersection';
gsap.registerPlugin(ScrollTrigger);
ScrollTrigger.defaults({ toggleActions: "restart pause resume pause" });
var splide = new Splide( '.splide', {
  type: 'loop',
  speed: 500,
  wheel:false,
  waitForTransition:true,
  fixedWidth : '60%',
  arrows:false,
  resetProgress:false,
  drag   : 'free',
  snap   : true,
  flickMaxPages: 1,
  flickPower:'500',
  focus: 'center',
  autoplay: 'pause',
  intersection: {
    inView: {
      autoplay: true,
    },
    outView: {
      autoplay: false,
    },
  },
  interval: 20000,
  perPage: 1,
  perMove: 1,
  gap: '1.5rem',
  breakpoints: {
    // 1200: { perPage: 1, gap: '2rem' },
    480 : { perPage: 1, fixedWidth : '100%', gap: 0 },
  },
});
var bar = splide.root.querySelector( '.splide__progress__bar' );
splide.on( 'mounted move', function () {
  var Autoplay = splide.Components.Autoplay;
  var end  = splide.Components.Controller.getEnd() + 1;
  var rate = Math.min( ( splide.index + 1 ) / end, 1 );
  bar.style.width = String( 100 * rate ) + '%';
});
splide.mount({ Intersection });
function dot() {
  var element = document.querySelectorAll( '.splide__pagination__page' );
  for (var i = 0; i < element.length; i++) {
  element[i].classList.add('cursor-hover-item');
  element[i].setAttribute("data-cursor-Text"," ");
  element[i].setAttribute("data-cursor-text-repeat","0")}
};
dot();
splide.on( 'autoplay:play', function () {
  var element = document.querySelector( '.splide__progress__bar' );
  element.classList.add("animatebar");
  element.style.animationPlayState = "running";
});
splide.on( 'autoplay:pause', function () {
  var element = document.querySelector( '.splide__progress__bar' );
  element.style.animationPlayState = "paused";
});
splide.on( 'active', function () {
var element = document.querySelector( '.splide__progress__bar' ); 
element.classList.remove("animatebar");
void element.offsetWidth;
element.classList.add("animatebar");
} );
let mm1 = gsap.matchMedia();
mm1.add("(min-width: 1341px)", () => {
  let tlh = gsap.timeline({ smoothChildTiming: true });
  tlh.fromTo(
    "nav",
    { y: -200 },
    { opacity: 1, y: 0, duration: 2, ease: "power.4" }
  );
  tlh.fromTo(
    ".text1",
    { opacity: 0, x: -800 },
    { opacity: 1, x: 0, duration: 2, ease: "power.4" },
    "-=2"
  );
  tlh.fromTo(
    ".logoc .img1",
    { opacity: 0, y: -400 },
    { opacity: 1, y: 0, duration: 2, ease: "power.4" },
    "-=2"
  );
  tlh.fromTo(
    ".link1 .img2",
    { opacity: 0, y: 400 },
    { opacity: 1, y: 0, duration: 2, ease: "power.4" },
    "-=2"
  );
  tlh.fromTo(
    ".text2",
    { opacity: 0, x: 800 },
    { opacity: 1, x: 0, duration: 2, ease: "power.4" },
    "-=2"
  );
  zenscroll.setup(null, 0);
});
mm1.add("(max-width: 1340px)", () => {
  let tlh2 = gsap.timeline({ smoothChildTiming: true });
  tlh2.fromTo(
    "nav",
    { y: -200 },
    { opacity: 1, y: 0, duration: 2, ease: "power.4" }
  );
  tlh2.fromTo(
    ".text1",
    { opacity: 0, x: -800 },
    { opacity: 1, x: 0, duration: 2, ease: "power.4" },
    "-=2"
  );
  tlh2.fromTo(
    ".logoc .img1",
    { opacity: 0, scale: 0 },
    { opacity: 1, scale: 1, duration: 2, ease: "power.4" },
    "-=2"
  );
  tlh2.fromTo(
    ".link1 .img2",
    { opacity: 0, y: 400 },
    { opacity: 1, y: 0, duration: 2, ease: "power.4" },
    "-=2"
  );
  tlh2.fromTo(
    ".text2",
    { opacity: 0, x: 800 },
    { opacity: 1, x: 0, duration: 2, ease: "power.4" },
    "-=2"
  );
  const navigationHeaight = document.querySelector("nav").offsetHeight;
  zenscroll.setup(null, navigationHeaight);
});
const bt1 = document.querySelector(".bt1");
gsap.to(bt1, {
  scrollTrigger: {
    scroller: window,
    trigger: ".link1",
    start: "top center",
    end: "80%",
    scrub: true,
    toggleClass: { targets: ".bt1", className: "is-active" },
  },
});
const bt2 = document.querySelector(".bt2");
gsap.to(bt2, {
  scrollTrigger: {
    scroller: window,
    trigger: ".link2",
    start: "top center",
    end: "80%",
    scrub: true,
    toggleClass: { targets: ".bt2", className: "is-active" },
  },
});
const bt3 = document.querySelector(".bt3");
gsap.to(bt3, {
  scrollTrigger: {
    scroller: window,
    trigger: ".link3",
    start: "top center",
    end: "80%",
    scrub: true,
    toggleClass: { targets: ".bt3", className: "is-active" },
  },
});
const bt4 = document.querySelector(".bt4");
gsap.to(bt4, {
  scrollTrigger: {
    scroller: window,
    trigger: ".link4",
    start: "top center",
    end: "80%",
    scrub: true,
    toggleClass: { targets: ".bt4", className: "is-active" },
  },
});
const bt5 = document.querySelector(".bt5");
gsap.to(bt5, {
  scrollTrigger: {
    scroller: window,
    trigger: ".link5",
    start: "top center",
    end: "80%",
    scrub: true,
    toggleClass: { targets: ".bt5", className: "is-active" },
    onEnter: () => {  
      // var Autoplay = splide.Components.Autoplay;
      // Autoplay.play(); 
      // bar.style.animationPlayState = "running";
    }
  },
});
const logo = document.querySelectorAll(".logocolor");
gsap.to(logo, {
  fill: "hsl(+=700, +=100%, -=50%)",
  scrollTrigger: {
    scroller: window,
    trigger: ".link1",
    start: "top top",
    end: "max",
    scrub: true,
  },
});
let mm = gsap.matchMedia();
mm.add("(min-width: 1341px)", () => {
  gsap.set(".menu", { y: 0, opacity: 1, boxShadow: "0 3px 5px -2px rgba(0,0,0,0.4)" });
});
mm.add("(max-width: 1340px)", () => {
  const hamburger = document.getElementById("hamburger");
  let tlm = gsap.timeline({});
  // tlm.to(".menu", { duration: 0.1, boxShadow: "0px 4px 6px 0px rgba(0,0,0,0.4)", ease: "power.4" });
  tlm.to(".menu", { duration: 0.4, y: 0, boxShadow: "0 3px 5px -2px rgba(3,4,7,0.28),0 7px 14px -5px rgba(3,4,7,0.3)", ease: "power.4" });
  tlm.to(".menu a", { duration: 0.4, opacity: 0.8, ease: "power.4" });
  document.getElementById("hamburger").addEventListener("click", menu);
  document.getElementById("homeB").addEventListener("click", menu);
  document.getElementById("processB").addEventListener("click", menu);
  document.getElementById("joinusB").addEventListener("click", menu);
  document.getElementById("teamB").addEventListener("click", menu);
  document.getElementById("infoB").addEventListener("click", menu);
  tlm.reversed(true);
  function menu() {
    tlm.reversed(!tlm.reversed());
    hamburger.classList.toggle("is-active");
  }
});
const accordionHeaders = document.querySelectorAll(".accordion-header");
function toggleActiveAccordion(_event, header) {
  const activeAccordion = document.querySelector(".accordion.active");
  const clickedAccordion = header.parentElement;
  const accordionBody = header.nextElementSibling;
  if (activeAccordion && activeAccordion != clickedAccordion) {
    activeAccordion.querySelector(".accordion-body").style.maxHeight = 0;
    activeAccordion.classList.remove("active");
  }
  clickedAccordion.classList.toggle("active");
  if (clickedAccordion.classList.contains("active")) {
    accordionBody.style.maxHeight = accordionBody.scrollHeight + "px";
  } else {
    accordionBody.style.maxHeight = 0;
  }
}
accordionHeaders.forEach(function (header) {
  header.addEventListener("click", function (event) {
    toggleActiveAccordion(event, header);
  });
});
function resizeActiveAccordionBody() {
  const activeAccordionBody = document.querySelector(
    ".accordion.active .accordion-body"
  );
  if (activeAccordionBody)
    activeAccordionBody.style.maxHeight =
      activeAccordionBody.scrollHeight + "px";
}
window.addEventListener("resize", function () {
  resizeActiveAccordionBody();
});
window.addEventListener("resize", removemenu);
function removemenu() {
  const hamburger = document.getElementById("hamburger");
  hamburger.classList.remove("is-active");
}
const open1 = document.getElementById("open-modal1");
const close1 = document.getElementById("close-modal1");
const modalWrapper1 = document.getElementById("modal-wrapper1");
const modal1 = document.getElementById("modal1");
const timeline1 = gsap.timeline({ ease: "easeInOut", duration: 0.2 });
const animateOpenModal1 = () => {
  timeline1
    .set(modalWrapper1, { y: 0 })
    .to(modalWrapper1, { clipPath: "circle(150% at 0 0)" })
    .to(modal1, { y: 0, opacity: 1 });
};
const animateCloseModal1 = () => {
  timeline1
    .to(modal1, { y: "20%", opacity: 0 })
    .to(modalWrapper1, { clipPath: "circle(0% at 0 0)" })
    .set(modalWrapper1, { y: "100%" });
};
open1.addEventListener("click", animateOpenModal1);
close1.addEventListener("click", animateCloseModal1);
modalWrapper1.addEventListener("click", (e) => {
  if (e.target.className !== "modal-wrapper1") return;
  animateCloseModal1();
});
const open2 = document.getElementById("open-modal2");
const close2 = document.getElementById("close-modal2");
const modalWrapper2 = document.getElementById("modal-wrapper2");
const modal2 = document.getElementById("modal2");
const timeline2 = gsap.timeline({ ease: "easeInOut", duration: 0.2 });
const animateOpenModal2 = () => {
  timeline2
    .set(modalWrapper2, { y: 0 })
    .to(modalWrapper2, { clipPath: "circle(150% at 0 0)" })
    .to(modal2, { y: 0, opacity: 1 });
};
const animateCloseModal2 = () => {
  timeline2
    .to(modal2, { y: "20%", opacity: 0 })
    .to(modalWrapper2, { clipPath: "circle(0% at 0 0)" })
    .set(modalWrapper2, { y: "100%" });
};
open2.addEventListener("click", animateOpenModal2);
close2.addEventListener("click", animateCloseModal2);
modalWrapper2.addEventListener("click", (e) => {
  if (e.target.className !== "modal-wrapper2") return;
  animateCloseModal2();
});
const open3 = document.getElementById("open-modal3");
const close3 = document.getElementById("close-modal3");
const modalWrapper3 = document.getElementById("modal-wrapper3");
const modal3 = document.getElementById("modal3");
const timeline3 = gsap.timeline({ ease: "easeInOut", duration: 0.2 });
const animateOpenModal3 = () => {
  timeline3
    .set(modalWrapper3, { y: 0 })
    .to(modalWrapper3, { clipPath: "circle(150% at 0 0)" })
    .to(modal3, { y: 0, opacity: 1 });
};
const animateCloseModal3 = () => {
  timeline3
    .to(modal3, { y: "20%", opacity: 0 })
    .to(modalWrapper3, { clipPath: "circle(0% at 0 0)" })
    .set(modalWrapper3, { y: "100%" });
};
open3.addEventListener("click", animateOpenModal3);
close3.addEventListener("click", animateCloseModal3);
modalWrapper3.addEventListener("click", (e) => {
  if (e.target.className !== "modal-wrapper3") return;
  animateCloseModal3();
});
const open4 = document.getElementById("open-modal4");
const close4 = document.getElementById("close-modal4");
const modalWrapper4 = document.getElementById("modal-wrapper4");
const modal4 = document.getElementById("modal4");
const timeline4 = gsap.timeline({ ease: "easeInOut", duration: 0.2 });
const animateOpenModal4 = () => {
  timeline4
    .set(modalWrapper4, { y: 0 })
    .to(modalWrapper4, { clipPath: "circle(150% at 0 0)" })
    .to(modal4, { y: 0, opacity: 1 });
};
const animateCloseModal4 = () => {
  timeline4
    .to(modal4, { y: "20%", opacity: 0 })
    .to(modalWrapper4, { clipPath: "circle(0% at 0 0)" })
    .set(modalWrapper4, { y: "100%" });
};
open4.addEventListener("click", animateOpenModal4);
close4.addEventListener("click", animateCloseModal4);
modalWrapper4.addEventListener("click", (e) => {
  if (e.target.className !== "modal-wrapper4") return;
  animateCloseModal4();
});
let mql = window.matchMedia("(pointer: fine");
if ((mql = true)) {
  const cursorOuter = document.querySelector(".cursor--large");
  const cursorInner = document.querySelector(".cursor--small");
  const cursorTextContainerEl = document.querySelector(".cursor--text");
  const cursorTextEl = cursorTextContainerEl.querySelector(".text");
  gsap.set([cursorInner, cursorOuter, cursorTextContainerEl], {
    xPercent: -50,
    yPercent: -50,
  });
  const hoverItems = document.querySelectorAll(".cursor-hover-item");
  const hoverEffectDuration = 0.01;
  let isHovered = false;
  const cursorRotationDuration = 20;
  let circleType = new CircleType(cursorTextEl);
  circleType.radius(50);
  hoverItems.forEach((item) => {
    item.addEventListener("pointerenter", handlePointerEnter);
    item.addEventListener("pointerleave", handlePointerLeave);
    document.addEventListener("pointerleave", pointerleave);
    document.addEventListener("pointerenter", pointerenter);
  });
  window.onclick = clickFunction;
  function clickFunction() {
    let tl = new gsap.timeline({});
    tl.to(cursorOuter, { duration: hoverEffectDuration * 10, scale: 3 });
    tl.to(cursorOuter, { duration: hoverEffectDuration * 10, scale: 1 });
  }
  var pos = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
  var mouse = { x: pos.x, y: pos.y };
  var speed = 0.15;
  var fpms = 60 / 1000;
  var xSet = gsap.quickSetter(
    [cursorInner, cursorOuter, cursorTextContainerEl],
    "x",
    "px"
  );
  var ySet = gsap.quickSetter(
    [cursorInner, cursorOuter, cursorTextContainerEl],
    "y",
    "px"
  );
  window.addEventListener("mousemove", (e) => {
    mouse.x = e.x;
    mouse.y = e.y;
  });
  gsap.ticker.add((time, deltaTime) => {
    var delta = deltaTime * fpms;
    var dt = 1.0 - Math.pow(1.0 - speed, delta);
    pos.x += (mouse.x - pos.x) * dt;
    pos.y += (mouse.y - pos.y) * dt;
    xSet(pos.x);
    ySet(pos.y);
  });
  if (!isHovered) {
    gsap.to(cursorTextContainerEl, {
      duration: hoverEffectDuration * 0.1,
      opacity: 0,
    });
    gsap.set(cursorTextContainerEl, { rotate: 0 });
  }
  function handlePointerEnter(e) {
    isHovered = true;
    const target = e.currentTarget;
    updateCursorText(target);
    gsap.fromTo(
      cursorTextContainerEl,
      {
        rotate: 0,
      },
      {
        duration: cursorRotationDuration,
        rotate: -360,
        ease: "none",
        repeat: -1,
      }
    );
    gsap.to(cursorInner, {
      duration: hoverEffectDuration,
      scale: 2,
      overwrite: "auto",
      immediateRender: true,
    });
    gsap.fromTo(
      cursorTextContainerEl,
      {
        duration: hoverEffectDuration,
        scale: 0,
        opacity: 0,
        overwrite: "auto",
        immediateRender: true,
      },
      {
        delay: hoverEffectDuration * 2,
        scale: 1.2,
        opacity: 1,
        overwrite: "auto",
        immediateRender: true,
      }
    );
    gsap.to(cursorOuter, {
      duration: 1,
      scale: 2,
      opacity: 0,
      overwrite: "auto",
      immediateRender: true,
    });
  }
  function handlePointerLeave() {
    isHovered = false;
    gsap.to([cursorInner, cursorOuter], {
      duration: hoverEffectDuration,
      scale: 1,
      opacity: 1,
      overwrite: "auto",
      immediateRender: true,
    });
    gsap.fromTo(
      cursorTextContainerEl,
      {
        duration: hoverEffectDuration,
        scale: 1,
        opacity: 1,
        overwrite: "auto",
        immediateRender: true,
      },
      {
        scale: 0.8,
        opacity: 0,
        overwrite: "auto",
        immediateRender: true,
      }
    );
  }
  function pointerenter() {
    gsap.to([cursorInner, cursorOuter], {
      duration: hoverEffectDuration,
      scale: 1,
      opacity: 1,
      overwrite: "auto",
      immediateRender: true,
    });
  }
  function pointerleave() {
    gsap.to([cursorInner, cursorOuter], {
      duration: hoverEffectDuration,
      scale: 0,
      opacity: 0,
      overwrite: "auto",
      immediateRender: true,
  });
}
  function updateCursorText(textEl) {
    const cursorTextRepeatTimes = textEl.getAttribute(
      "data-cursor-text-repeat"
    );
    const cursorText = returnMultipleString(
      textEl.getAttribute("data-cursor-text"),
      cursorTextRepeatTimes
    );
    circleType.destroy();
    cursorTextEl.innerHTML = cursorText;
    circleType = new CircleType(cursorTextEl);
    circleType.refresh();
  }
  function returnMultipleString(string, count) {
    let s = "";
    for (let i = 0; i < count; i++) {
      s += ` ${string} `;
    }
    return s;
  }
} else {
  gsap.set([cursorInner, cursorOuter, cursorTextContainerEl, cursorTextEl], {
    display: "none",
  });
}
window.addEventListener('hashchange', function(){
history.replaceState('', document.title, window.location.origin + window.location.pathname + window.location.search);
});
